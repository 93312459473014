import {useState} from 'react';
import Cookies from 'utils/cookies';
import {COOKIE_CONSENT_VERSION} from 'configs';

function useCookieConsent() {
  const {version, ...cookieConsent} = Cookies.consent || {};
  const [consents, setConsents] = useState(cookieConsent || {});

  const outdatedVersion = version !== COOKIE_CONSENT_VERSION;

  const [showBanner, setShowBanner] = useState(outdatedVersion);
  const [isBannerVisible, setIsBannerVisible] = useState(outdatedVersion);
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function onAcceptCookies(consent) {
    const payload = {...consent, version: COOKIE_CONSENT_VERSION};

    Cookies.consent = payload;
    setConsents(payload);

    setIsOpen(false);
    setShowBanner(false);
    // transition on css is set to 0.5s
    setTimeout(() => setIsBannerVisible(false), 500);
  }

  return {
    isOpen,
    showBanner,
    isBannerVisible,
    toggleOpen,
    onAcceptCookies,
    consents,
    setConsents,
  };
}

export default useCookieConsent;
