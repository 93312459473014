import React, {useState} from 'react';
import {FormSubmit} from 'components/Forms';
import {COOKIE_CONSENT_TYPES} from 'configs/types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CustomInput,
} from 'reactstrap';

const consents = [
  {
    title: 'Necessary',
    name: COOKIE_CONSENT_TYPES.necessary,
    description:
      'Necessary cookies keep our site running smoothly by supporting key functions like navigation. The website cannot function without these cookies.',
    necessary: true,
  },
  {
    title: 'Statistics',
    name: COOKIE_CONSENT_TYPES.statistic,
    description:
      'These cookies help us to understand how visitors interact with the website by collecting and reporting information anonymously, allowing us to improve your experience on our website.',
  },
];

function CookieConsentContent(props) {
  const {title, name, description, necessary} = props;
  const [checked, setChecked] = useState(!!necessary);

  function toggleChecked(e) {
    setChecked(e.target.checked);
  }

  return (
    <div className="mb-4">
      <div className="d-flex">
        <h5 className="flex-grow-1">{title}</h5>
        <CustomInput
          id={name}
          type="switch"
          size="sm"
          disabled={necessary}
          onChange={toggleChecked}
          checked={checked}
        />
        <input type="hidden" name={name} value={checked ? '1' : '0'} />
      </div>
      <div className="text-muted">{description}</div>
    </div>
  );
}

function CookieConsentModal(props) {
  const {isOpen, toggleOpen, onAcceptCookies} = props;

  function handleSubmit(formData) {
    Object.keys(formData).forEach((key) => {
      formData[key] = formData[key] === '1';
    });

    onAcceptCookies(formData);
    window.dataLayer.push({
      cookieConsentNecessary: formData.necessary,
      cookieConsentStatistics: formData.statistic,
      event: 'accept cookie consent modal',
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader toggle={toggleOpen}>Cookie Preferences</ModalHeader>
      <FormSubmit onSubmit={handleSubmit} isSubmitting={false}>
        <ModalBody>
          <p className="mb-4">
            This site uses cookies. We mainly use cookies to improve your
            experience on our website. You can choose which types of cookies you
            want to allow and change your preferences at any time. Disabling
            cookies may affect your experience on this website.
          </p>
          {consents.map((consent, index) => (
            <CookieConsentContent key={index} {...consent} />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline type="submit">
            Accept cookie selection
          </Button>
          <Button
            color="primary"
            onClick={() => onAcceptCookies({necessary: true, statistic: true})}
          >
            Accept all cookies
          </Button>
        </ModalFooter>
      </FormSubmit>
    </Modal>
  );
}

export default CookieConsentModal;
