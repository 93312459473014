import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {isObject} from 'lodash/lang';
import {hasIn} from 'lodash/object';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import ImageAvatar from '../Images/ImageAvatar';

export default class ContentProfile extends React.Component {
  static propTypes = {
    image: PropTypes.string,
    email: PropTypes.any,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        text: PropTypes.string,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        onClick: PropTypes.func,
      }),
    ]),
    imageProps: PropTypes.object,
  };

  get profileImage() {
    let name = this.props.name;
    if (
      isObject(this.props.name) &&
      (hasIn(this.props.name, 'to') || hasIn(this.props.name, 'onClick'))
    )
      name = this.props.name.text;

    return (
      <ImageAvatar
        image={this.props.image}
        name={name}
        size="40"
        {...this.props.imageProps}
      />
    );
  }

  get profileName() {
    if (
      isObject(this.props.name) &&
      (hasIn(this.props.name, 'to') || hasIn(this.props.name, 'onClick'))
    ) {
      const {text, to, onClick} = this.props.name;

      return (
        <Link
          to={to || ''}
          onClick={onClick}
          className="text-ellipsis font-weight-bold"
        >
          {text}
        </Link>
      );
    }

    return (
      <span className="text-ellipsis font-weight-bold">{this.props.name}</span>
    );
  }

  get profileEmail() {
    return (
      <div className="text-muted font-size-sm text-ellipsis">
        {this.props.email}
      </div>
    );
  }

  render() {
    return (
      <div
        className={classNames(styles['content-profile'], this.props.className)}
      >
        <div className={styles['content-profile__image']}>
          {this.profileImage}
        </div>
        <div
          className={classNames(
            'notranslate',
            styles['content-profile__detail']
          )}
        >
          {this.profileName}

          {this.props.email && this.profileEmail}
        </div>
      </div>
    );
  }
}
